.dashboard {
    &_main {
        margin-top: 15px;
        padding: 0 15px;
        .form-control {
            background: #fff;
            width: 100%;
            height: 35px;
            font-size: 13px;
            padding: 10px;
        }
        .top_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .search_form {
                display: flex;
                width: 25%;
                select {
                    height: inherit;
                    border: 1px solid #aaa;
                    box-shadow: inset 0 2px 0 #0000001a;
                    border-radius: 0;
                    border-right: 0;
                    width: 120px;
                    -webkit-appearance: none;
                    @include bp(mini) {
                        width: 75px;
                    }
                }
                .search_box {
                    background-color: $white;
                    position: relative;
                    @include bp(mini) {
                        width: 166px;
                    }
                    svg {
                        position: absolute;
                        z-index: 1;
                        top: 50%;
                        left: 2px;
                        display: block;
                        content: "";
                        width: 19px;
                        height: 19px;
                        transform: translateY(-50%);
                    }
                    input {
                        width: 280px;
                        padding-left: 25px;
                        margin: 0;
                        height: 37px;
                        border: 1px solid #aaa;
                        box-shadow: inset 0 2px 0 #0000001a;
                        @include bp(mini) {
                            width: 166px;
                        }
                    }
                    button {
                        position: absolute;
                        top: 50%;
                        right: 5px;
                        width: 60px;
                        height: 30px;
                        border: 0;
                        border-radius: 2px;
                        transform: translateY(-50%);
                        font-size: 13px;
                        font-weight: 700;
                        cursor: pointer;
                        font-family: Arial, sans-serif;
                        background: $menucolor1;
                        color: $menufontcolor;
                    }
                    .search-result{
                        position: absolute;
                        z-index: 99;
                        background: white;
                        top: 50px;
                        left: 100px;
                    }
                }
            }
            .agents {
                width: 33.33%;
                justify-content: center !important;
                display: flex !important;
                align-items: center !important;
                .agentlist {
                    background: url(data:image/gif;base64,R0lGODlhBQAeAIAAAO7u7vb29iH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4zLWMwMTEgNjYuMTQ1NjYxLCAyMDEyLzAyLzA2LTE0OjU2OjI3ICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M2IChNYWNpbnRvc2gpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjlCM0ZCODUwNThENjExRTY4OEYxQkI1OTY0QzQ2OEYwIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjAzRTQ4QjFBNThENzExRTY4OEYxQkI1OTY0QzQ2OEYwIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OUIzRkI4NEU1OEQ2MTFFNjg4RjFCQjU5NjRDNDY4RjAiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OUIzRkI4NEY1OEQ2MTFFNjg4RjFCQjU5NjRDNDY4RjAiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4B//79/Pv6+fj39vX08/Lx8O/u7ezr6uno5+bl5OPi4eDf3t3c29rZ2NfW1dTT0tHQz87NzMvKycjHxsXEw8LBwL++vby7urm4t7a1tLOysbCvrq2sq6qpqKempaSjoqGgn56dnJuamZiXlpWUk5KRkI+OjYyLiomIh4aFhIOCgYB/fn18e3p5eHd2dXRzcnFwb25tbGtqaWhnZmVkY2JhYF9eXVxbWllYV1ZVVFNSUVBPTk1MS0pJSEdGRURDQkFAPz49PDs6OTg3NjU0MzIxMC8uLSwrKikoJyYlJCMiISAfHh0cGxoZGBcWFRQTEhEQDw4NDAsKCQgHBgUEAwIBAAAh+QQAAAAAACwAAAAABQAeAAACDYyPoMvtD6OctNqLWQEAOw==);
                    border: 1px solid #7e97a7;
                    border-radius: 3px;
                    display: inline-block;
                    padding: 0;
                    margin: 0;
                    li {
                        position: relative;
                        font-size: 16px;
                        line-height: 26px;
                        font-weight: 700;
                        list-style: none;
                        padding: 0 5px 0 10px;
                        display: flex;
                        a {
                            text-decoration: none;
                            color: #0d6efd;
                            margin-right: 5px;
                            span {
                                margin-top: 5px;
                                font-weight: lighter;
                                width: 27px;
                                height: 15px;
                                line-height: 15px;
                                text-decoration: none !important;
                                border-radius: 4px;
                                margin-right: 5px;
                                font-size: 10px;
                                display: inline-block;
                                text-align: center;
                                background: #055cc0;
                                color: white;
                            }
                            strong {
                                display: inline-block;
                                vertical-align: middle;
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
            &_btn {
                width: 33.33333333%;
                display: flex;
                justify-content: flex-end;
                a {
                    &.btn {
                        padding: 4px 12px;
                        &:last-child {
                            margin-left: 5px;
                        }
                    }
                    &.add_player {
                        svg {
                            width: 20px;
                            vertical-align: middle;
                            margin-right: 5px;
                            path {
                                fill: #6c757d;
                                transition: fill 0.15s ease-in-out;
                            }
                        }
                        &:hover {
                            svg {
                                path {
                                    fill: $white;
                                }
                            }
                        }
                    }
                    &.add_agent {
                        svg {
                            width: 20px;
                            vertical-align: middle;
                            margin-right: 5px;
                            path {
                                fill: #0d6efd;
                                transition: fill 0.15s ease-in-out;
                            }
                        }
                        &:hover {
                            svg {
                                path {
                                    fill: $white;
                                }
                            }
                        }
                    }
                }
            }
        }
        .downlist_remain {
            &_wrp {
                width: 100%;
                padding: 7px 0 5px;
                margin-top: 15px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #7e97a7;
                margin-bottom: 15px;
                background: #ffffff;
            }
            ul {
                width: 100%;
                display: flex;
                justify-content: space-between;
                .downlist_remain_item {
                    width: 16.32%;
                    padding: 5px 10px;
                    border-right: 1px solid #d2d2d2;
                    &:last-child {
                        border-right: 0;
                    }
                    span {
                        margin: 0 0 5px;
                        display: block;
                        color: #000;
                        font-weight: bold;
                    }
                    h4 {
                        font-size: 15px;
                        line-height: 20px;
                        font-weight: 700;
                        display: flex;
                        span {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
    &_users {
        margin-top: 15px;
        background-color: #fff;
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                display: flex;
                padding: 10px 20px;
                align-items: center;
                label {
                    margin-right: 5px;
                    font-size: 14px;
                    font-weight: bold;
                }
                span {
                    font-size: 14px;
                }
            }
        }
    }
}
.table01 {
    width: 100%;
    background-color: #fff;
    border-collapse: collapse;
    border-bottom: 1px solid #7e97a7;
    margin-bottom: 15px;
    th {
        color: #243a48;
        background-color: #e4e4e4;
        border: 1px solid #7e97a7;
        border-width: 1px 0 1px 0;
        padding: 8px 10px;
        text-align: left;
    }
    td {
        border-top: 1px solid #d5cdcd;
        padding: 8px 10px;
        vertical-align: middle;
        img {
            max-width: 150px;
            max-height: 50px;
            object-fit: contain;
        }
        a {
            svg {
                width: 12px;
                path {
                    fill: #0d6efd;
                }
            }
            &:hover {
                color: #0a58ca;
                svg {
                    path {
                        fill: #0a58ca;
                    }
                }
            }
        }
        .badge {
            padding: 3px 5px;
            svg {
                width: 10px;
                display: inline-block;
                margin-right: 5px;
                vertical-align: middle;
            }
        }
        &.status {
            .badge {
                padding: 8px;
                svg {
                    vertical-align: middle;
                    path {
                        fill: $white;
                    }
                }
            }
        }
        .btn-group {
            button {
                &:first-child {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
            a {
                margin-left: -1px;
                border-radius: 0;
                display: flex;
                align-items: center;
                &:last-child {
                    border-radius: 4px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }
}
.update_status {
    &_block {
        padding: 0 12px;
        .d_flex {
            justify-content: space-between;
            .badge {
                margin-right: 5px;
            }
        }
        &_user {
            margin-bottom: 15px;
        }
        &_content {
            margin-bottom: 15px;
            &_item {
                flex: 0 0 auto;
                width: 33.33333333%;
                padding: 0 12px;
                .card {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    min-width: 0;
                    word-wrap: break-word;
                    background-color: #fff;
                    background-clip: border-box;
                    border: 1px solid rgba(0, 0, 0, 0.125);
                    border-radius: 0.25rem;
                    &.active {
                        &:hover {
                            background-color: $greenbg;
                            color: white;
                            cursor: pointer;
                            svg {
                                width: 35px;
                                path {
                                    fill: $white;
                                }
                            }
                        }
                    }
                    &.suspend {
                        &:hover {
                            background-color: #dc3545;
                            color: white;
                            cursor: pointer;
                            svg {
                                path {
                                    fill: $white;
                                }
                            }
                        }
                    }
                    &.locked {
                        &:hover {
                            background-color: #212529;
                            color: white;
                            cursor: pointer;
                            svg {
                                path {
                                    fill: $white;
                                }
                            }
                        }
                    }
                    &.selected {
                        &.active {
                            background-color: $greenbg;
                            color: white;
                            cursor: pointer;
                            svg {
                                width: 35px;
                                path {
                                    fill: $white;
                                }
                            }
                        }
                        &.suspend {
                            background-color: #dc3545;
                            color: white;
                            cursor: pointer;
                            svg {
                                path {
                                    fill: $white;
                                }
                            }
                        }
                        &.locked {
                            background-color: #212529;
                            color: white;
                            cursor: pointer;
                            svg {
                                path {
                                    fill: $white;
                                }
                            }
                        }
                    }
                    &-body {
                        flex: 1 1 auto;
                        padding: 16px;
                        text-align: center;
                        svg {
                            width: 35px;
                            height: 40px;
                            margin-bottom: 10px;
                            path {
                                fill: $black;
                            }
                        }
                        .card-title {
                            margin-bottom: 8px;
                            font-size: 20px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}

.search-result{
    position: absolute;
    z-index: 99;
    background: white;
    top: 55px;
    }