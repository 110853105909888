@mixin bp($size) {
	@if $size ==mini {
		@media (max-width: 992px) {
			@content;
		}
	}
	@if $size ==tab {
		@media (max-width: 768px) {
			@content;
		}
	}
	@if $size ==mob {
		@media (max-width: 576px) {
			@content;
		}
	}
	@else {
		@media (max-width: $size) {
			@content;
		}
	}
}

@mixin suspendminimumbp($size) {
	@if $size ==mob {
		@media (max-width: 992px) {
			@content;
		}
	}
}

// Flex
@mixin flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
@mixin align_center {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
@mixin justify_center {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
@mixin justify_between {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
@mixin flex_1_auto {
	-webkit-box-flex: 1;
	-ms-flex: 1 auto;
	flex: 1 auto;
}
@mixin flex_align_center {
	@include flex;
	@include align_center;
}
@mixin flex_justify_between {
	@include flex;
	@include justify_between;
}
@mixin flex_justify_center {
	@include flex;
	@include justify_center;
}
@mixin flex_center_center {
	@include flex;
	@include align_center;
	@include justify_center;
}
@mixin flex_center_between {
	@include flex;
	@include align_center;
	@include justify_between;
}
// Flex Direction
@mixin flex_dir_column {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
@mixin flex_dir_column_rev {
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	-ms-flex-direction: column-reverse;
	flex-direction: column-reverse;
}
@mixin flex_dir_row {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}
@mixin flex_column {
	@include flex;
	@include flex_dir_column;
}
@mixin f_wrap {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
@mixin flex_wrap {
	@include flex;
	@include f_wrap;
}
@mixin flex_shrink {
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
// Width and height 
@mixin square($pixels: null) {
	width: $pixels;
	height: $pixels;
}