.box {
    text-align: center;
    width: 260px;
    height: 230px;
    padding: 25px;
    margin: auto;
    margin-top: 150px;
    box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
    font-family: "Nunito Sans", sans-serif;
}

.box h2 {
    padding-bottom: 10px;
}

.box p {
    padding-bottom: 10px;
}

.box button {
    border: 2px solid #4154f1;
    padding: 8px 15px 8px 15px;
    border-radius: 20px;
    font-size: 20px;
}

.box .for {
    font-weight: bolder;
    color: #4154f1;
    padding-top: 10px;
}